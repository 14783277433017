import React from 'react';

const EditButton = ({ onClick }) => {
  return (
    <button
      style={{
        backgroundColor: '#3498db',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        padding: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        maxWidth: '80px'
      }}
      onClick={onClick}
    >
      Edit ✏️
    </button>
  );
};

export default EditButton;