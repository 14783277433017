// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: dodgerblue;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.button:hover {
  background-color: royalblue;
}

.button:active {
  background-color: dodgerblue;
  transform: translateY(1px);
}

.sign-button {
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: rgb(10, 165, 38);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.sign-button:hover {
  background-color: rgb(6, 140, 31);
}


.sign-button:active {
  background-color: dodgerblue;
  transform: translateY(1px);

}`, "",{"version":3,"sources":["webpack://./src/Components/Buttons/ButtonStyle.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,4BAA4B;EAC5B,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,6CAA6C;AAC/C;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,4BAA4B;EAC5B,0BAA0B;AAC5B;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,kCAAkC;EAClC,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,6CAA6C;AAC/C;;AAEA;EACE,iCAAiC;AACnC;;;AAGA;EACE,4BAA4B;EAC5B,0BAA0B;;AAE5B","sourcesContent":[".button {\n  padding: 8px 16px;\n  font-size: 16px;\n  font-weight: bold;\n  color: white;\n  background-color: dodgerblue;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.3s ease-in-out;\n}\n\n.button:hover {\n  background-color: royalblue;\n}\n\n.button:active {\n  background-color: dodgerblue;\n  transform: translateY(1px);\n}\n\n.sign-button {\n  padding: 8px 16px;\n  font-size: 16px;\n  font-weight: bold;\n  color: white;\n  background-color: rgb(10, 165, 38);\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.3s ease-in-out;\n}\n\n.sign-button:hover {\n  background-color: rgb(6, 140, 31);\n}\n\n\n.sign-button:active {\n  background-color: dodgerblue;\n  transform: translateY(1px);\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
