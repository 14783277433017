import "./InputStyle.css";

export const SignatureInput = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  name,
  max,
  required,
}) => {
  return (
    <div className="input-field">
      <label className="input-label">{label}</label>
      <input
        className="signature-input"
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        maxLength={max}
        required={required}
        disabled='true'
      />
    </div>
  );
};
