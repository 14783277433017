import './PageStyle.css';
import { ButtonOne } from '../Components/Buttons/ButtonOne';

const AskIfCoApp = ({handleAskIfCoApp}) => {
  return (
    <div className="main-container">
      <div className="content">
        <div className="logo">
        </div>
        <div className="greetings-message">
        Please let us know if you will be using a co-applicant by selecting
          the appropriate option below.
        </div>
        <br />
        <br />

        <div className="button-question-div">
          <ButtonOne onClick={() => handleAskIfCoApp('yes')} label='Yes'/>
          <ButtonOne onClick={() => handleAskIfCoApp('no')} label='No'/>
        </div>
      </div>
    </div>
  );
};

export default AskIfCoApp;