
import "./PageStyle.css";
import { ButtonOne } from "../Components/Buttons/ButtonOne";

const SubmitMessage = ({ handleFormSubmit }) => {
  return (
    <div className="main-container">
      <div className="content">
        <div className="logo">
          <h1>JKT Homes 🏠</h1>
        </div>
        <div className="greetings-message">
        Thank you for your submission! We'll review it promptly and be in touch shortly.
        </div>
      </div>
    </div>
  );
};

export default SubmitMessage;
