import './PageStyle.css';
import { ButtonOne } from '../Components/Buttons/ButtonOne';
import { InputField } from '../Components/Inputs/InputOne';
import DatePickerInput from '../Components/Inputs/DatePicker';
import { AddressInput } from '../Components/Inputs/AdressInput';
import { ApplicantEmploymentHistoryContext } from '../App';
import { useContext } from 'react';

const ApplicantEmploymentHistory = ({
  handleApplicantEmploymentHistoryChange,
  handleApplicantEmploymentSubmit,
  applicantInfoError,
  isEditingPage,
  handleSaveButton,
}) => {
  const {
    currentEmployer,
    employmentStreetAddress,
    employmentCityName,
    employmentStateName,
    employmentZipCode,
    employmentPhone,
    grossSalary,
    jobTitle,
    dateOfEmployment,
  } = useContext(ApplicantEmploymentHistoryContext);
  return (
    <div className="content-div">
      <div className="main-container">
        <div className="content">
        <h3>Employment History</h3>
          <form
            onSubmit={handleApplicantEmploymentSubmit}
            className="form-section"
            action=""
          >
            <InputField
              value={currentEmployer}
              name="currentEmployer"
              label="* Current employer"
              type="text"
              onChange={handleApplicantEmploymentHistoryChange}
              required="true"
            />
            <AddressInput
              onChange={handleApplicantEmploymentHistoryChange}
              streetValue={employmentStreetAddress}
              streetName="employmentStreetAddress"
              cityValue={employmentCityName}
              cityName="employmentCityName"
              stateValue={employmentStateName}
              stateName="employmentStateName"
              zipValue={employmentZipCode}
              zipName="employmentZipCode"
              type="text"
              label="* Employer address"
            />

            <InputField
              value={employmentPhone}
              name="employmentPhone"
              label="* Employer phone number"
              type="text"
              onChange={handleApplicantEmploymentHistoryChange}
              required="true"
            />

            <InputField
              value={grossSalary}
              name="grossSalary"
              label="* Gross salary"
              type="text"
              onChange={handleApplicantEmploymentHistoryChange}
              required="true"
            />

            <InputField
              value={jobTitle}
              name="jobTitle"
              label="* Job title"
              type="text"
              onChange={handleApplicantEmploymentHistoryChange}
            />

            <DatePickerInput
              value={dateOfEmployment}
              name="dateOfEmployment"
              label="* Start date of employment"
              onChange={handleApplicantEmploymentHistoryChange}
              required="true"
            />
            {applicantInfoError && (
              <p style={{ textAlign: 'center', color: 'red' }}>
                All Fields Required
              </p>
            )}
            {isEditingPage ? (
              <ButtonOne onClick={handleSaveButton} label="Save" />
            ) : (
              <ButtonOne label="Continue" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ApplicantEmploymentHistory;
