import './PageStyle.css';
import { ButtonOne } from '../Components/Buttons/ButtonOne';
import { InputField } from '../Components/Inputs/InputOne';
import { AddressInput } from '../Components/Inputs/AdressInput';
import TextArea from '../Components/Inputs/TextArea';
import RadioInput from '../Components/Inputs/RadioInput';
import { ApplicantPreviousAddressInfoContext } from '../App';
import { useContext } from 'react';

const ApplicantPreviousAddressInfo = ({
  handleApplicantPreviousAddressChange,
  handleApplicantAddressInfoSubmit,
  isEditingPage,
  handleSaveButton
}) => {
  const {
    prevApplicantStreetAddress,
    prevApplicantCity,
    prevApplicantState,
    prevApplicantZipCode,
    applicantLandlordName,
    applicantLandlordNumber,
    applicantEvicted,
    applicantEvictedReason,
  } = useContext(ApplicantPreviousAddressInfoContext);
  return (
    <div>
      <div className="main-container">
        <div className="content">
        <h3>Address Information</h3>
          <form
            onSubmit={handleApplicantAddressInfoSubmit}
            className="form-section"
            action=""
          >
            <AddressInput
              streetValue={prevApplicantStreetAddress}
              streetName="prevApplicantStreetAddress"
              cityValue={prevApplicantCity}
              cityName="prevApplicantCity"
              stateValue={prevApplicantState}
              stateName="prevApplicantState"
              zipValue={prevApplicantZipCode}
              zipName="prevApplicantZipCode"
              type="text"
              label="* Previous address"
              onChange={handleApplicantPreviousAddressChange}
              required="true"
            />

            <InputField
              value={applicantLandlordName}
              name="applicantLandlordName"
              label="Landlord/manager name"
              type="text"
              onChange={handleApplicantPreviousAddressChange}
            />

            <InputField
              value={applicantLandlordNumber}
              name="applicantLandlordNumber"
              label="Landlord/manager number"
              type="text"
              onChange={handleApplicantPreviousAddressChange}
            />

            <RadioInput
              name="applicantEvicted"
              wasEvicted={applicantEvicted}
              onChange={handleApplicantPreviousAddressChange}
            />

            <TextArea
              value={applicantEvictedReason}
              onChange={handleApplicantPreviousAddressChange}
              name="applicantEvictedReason"
            />
            {isEditingPage ? (
              <ButtonOne onClick={handleSaveButton} label="Save" />
            ) : (
              <ButtonOne label="Continue" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ApplicantPreviousAddressInfo;
