import "./PageStyle.css";
import { ButtonOne } from "../Components/Buttons/ButtonOne";

const GreetingsPage = ({ handlePageChange }) => {
  return (
    <div className="main-container">
      <div className="content">
        <div className="logo">
          <h1>JKT Homes 🏠</h1>
          <h5 style={{textAlign: 'center'}}>Application (Owner Financed)</h5>
        </div>
        <div className="greetings-message">
          You are about to fill out an application for a property on a Lease
          Purchase contract. Fill out the application as thoroughly as possible
        </div>

        <div className="instruction-div">
          <div className="button-div">
            <ButtonOne
              onClick={() => handlePageChange("applicantInformation")}
              label="Start"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GreetingsPage;
