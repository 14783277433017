import * as yup from "yup";

//!Applicant information yup validation
export const applicantInfoSchema = yup.object().shape({
  applicantFirstName: yup.string().required('First name is required.'),
  applicantLastName: yup.string().required('Last name is required.'),
  applicantDateOfBirth: yup.date().required('Date of birth is required.'),
  applicantPhoneNumber: yup.string().required('Phone number is required.'),
  applicantEmailAddress: yup.string().email('Invalid email address.').required('Email address is required.'),
  applicantSsn: yup.string().min(11, 'SSN must be at least 11 characters.').required('SSN is required.'),
  applicantStreetAddress: yup.string().required('Street address is required.'),
  applicantCity: yup.string().required('City is required.'),
  applicantState: yup.string().required('State is required.'),
  applicantZipCode: yup.string().required('Zip code is required.'),
});


export const applicantPrevAddressSchema = yup.object().shape({
  prevApplicantStreetAddress: yup.string().required('Previous street address is required'),
  prevApplicantCity: yup.string().required('Previous city name is required'),
  prevApplicantState: yup.string().required('Previous state is required'),
  prevApplicantZipCode: yup.string().required('Previous zip code is required'),
  applicantLandlordName: yup.string().required(),
  applicantLandlordNumber: yup.string().required(),
  applicantEvicted: yup.string().required(),
  applicantEvictedReason: yup.string(),
})


export const applicantEmploymentHistorySchema = yup.object().shape({
  currentEmployer: yup.string().required(),
  employmentStreetAddress: yup.string().required(),
  employmentCityName: yup.string().required(),
  employmentStateName: yup.string().required(),
  employmentZipCode: yup.string().required(),
  employmentPhone: yup.string().required(),
  grossSalary: yup.string().required(),
  jobTitle: yup.string().required(),
  dateOfEmployment: yup.string().required(),
})