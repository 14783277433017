import "./InputStyle.css";

const RadioInput = ({ onChange, wasEvicted, name}) => {
  return (
    <div className="input-field">
      <label className="input-label" htmlFor="radio-section">
        * Have you ever been evicted?
      </label>
      <div>
        <label>
          <input
            onChange={onChange}
            type="radio"
            name={name}
            value="yes"
            checked={wasEvicted === 'yes'}
          />
          Yes
        </label>
        <label>
          <input
            onChange={onChange}
            type="radio"
            name={name}
            value="no"
            checked={wasEvicted === 'no'}
          />
          No
        </label>
      </div>
    </div>
  );
};

export default RadioInput;
