import '../PageStyle.css';
import { ButtonOne } from '../../Components/Buttons/ButtonOne';
import { InputField } from '../../Components/Inputs/InputOne';
import { AddressInput } from '../../Components/Inputs/AdressInput';
import TextArea from '../../Components/Inputs/TextArea';
import RadioInput from '../../Components/Inputs/RadioInput';
import { CoAppPrevAddressContext } from '../../App';
import { useContext } from 'react';

const CoApplicantPreviousAddress = ({
  coAppPrevAddressChange,
  handleCoAppPrevAddressSubmit,
  isEditingPage,
  handleSaveButton
}) => {
  const {
    coPrevApplicantStreetAddress,
    coPrevApplicantCity,
    coPrevApplicantState,
    coPrevApplicantZipCode,
    coLandLordName,
    coLandlordNumber,
    coEvicted,
    coEvictedReason,
  } = useContext(CoAppPrevAddressContext);
  return (
    <div>
      <div className="main-container">
        <div className="content">
        <h3>Co-Applicant Address Information</h3>
          <form
            onSubmit={handleCoAppPrevAddressSubmit}
            className="form-section"
            action=""
          >
            <AddressInput
              streetValue={coPrevApplicantStreetAddress}
              streetName="coPrevApplicantStreetAddress"
              cityValue={coPrevApplicantCity}
              cityName="coPrevApplicantCity"
              stateValue={coPrevApplicantState}
              stateName="coPrevApplicantState"
              zipValue={coPrevApplicantZipCode}
              zipName="coPrevApplicantZipCode"
              type="text"
              label="* Co-Applicant Previous address"
              onChange={coAppPrevAddressChange}
              required="true"
            />

            <InputField
              value={coLandLordName}
              name="coLandLordName"
              label="Co-Applicant Landlord/manager name"
              type="text"
              onChange={coAppPrevAddressChange}
              
            />

            <InputField
              value={coLandlordNumber}
              name="coLandlordNumber"
              label="Co-Applicant Landlord/manager number"
              type="text"
              onChange={coAppPrevAddressChange}
              
            />

            <RadioInput
              name="coEvicted"
              wasEvicted={coEvicted}
              onChange={coAppPrevAddressChange}
            />

            <TextArea
              value={coEvictedReason}
              onChange={coAppPrevAddressChange}
              name="coEvictedReason"
            />
            {isEditingPage ? (
              <ButtonOne onClick={handleSaveButton} label="Save" />
            ) : (
              <ButtonOne label="Continue" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CoApplicantPreviousAddress;
