import './PageStyle.css';
import { ButtonOne } from '../Components/Buttons/ButtonOne';
import { InputField } from '../Components/Inputs/InputOne';
import DatePickerInput from '../Components/Inputs/DatePicker';
import { AddressInput } from '../Components/Inputs/AdressInput';
import { ApplicantInformationContext } from '../App';
import { useContext } from 'react';

const ApplicantInformation = ({
  handleApplicantInfoChange,
  handleApplicantInformationSubmit,
  applicantInfoError,
  isEditingPage,
  handleSaveButton
}) => {
  const {
    applicantFirstName,
    applicantMiddleName,
    applicantLastName,
    applicantDateOfBirth,
    applicantPhoneNumber,
    applicantEmailAddress,
    applicantSsn,
    applicantStreetAddress,
    applicantCity,
    applicantState,
    applicantZipCode,
  } = useContext(ApplicantInformationContext);
  return (
    <div className="content-div">
      <div className="main-container">
        
        <div className="content">
          <h3>Applicant Information</h3>
          <form
            onSubmit={handleApplicantInformationSubmit}
            className="form-section"
            action=""
          >
            <InputField
              value={applicantFirstName}
              name="applicantFirstName"
              label="* First name"
              type="text"
              onChange={handleApplicantInfoChange}
              required="true"
            />
            <InputField
              value={applicantMiddleName}
              name="applicantMiddleName"
              label="Middle name"
              type="text"
              onChange={handleApplicantInfoChange}
            />
            <InputField
              value={applicantLastName}
              name="applicantLastName"
              label="* Last name"
              type="text"
              onChange={handleApplicantInfoChange}
              required="true"
            />
            <DatePickerInput
              value={applicantDateOfBirth}
              name="applicantDateOfBirth"
              label="* Date of birth"
              onChange={handleApplicantInfoChange}
              required="true"
            />
            <InputField
              value={applicantPhoneNumber}
              name="applicantPhoneNumber"
              label="* Phone number"
              type="type="
              onChange={handleApplicantInfoChange}
              required="true"
            />
            <InputField
              value={applicantEmailAddress}
              name="applicantEmailAddress"
              label="* Email address"
              type="text"
              onChange={handleApplicantInfoChange}
              required="true"
            />
            <InputField
              value={applicantSsn}
              name="applicantSsn"
              label="* Social Security Number/ITIN"
              type="text"
              onChange={handleApplicantInfoChange}
              max="11"
              min='11'
              required="true"
            />
            <AddressInput
              onChange={handleApplicantInfoChange}
              streetValue={applicantStreetAddress}
              streetName="applicantStreetAddress"
              cityValue={applicantCity}
              cityName="applicantCity"
              stateValue={applicantState}
              stateName="applicantState"
              zipValue={applicantZipCode}
              zipName="applicantZipCode"
              type="text"
              label="* Current address"
              required="true"
            />
            {applicantInfoError && (
              <p style={{ textAlign: 'center', color: 'red' }}>
                All Fields Required
              </p>
            )}

            {isEditingPage ? (
              <ButtonOne onClick={handleSaveButton} label="Save" />
            ) : (
              <ButtonOne label="Continue" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ApplicantInformation;
