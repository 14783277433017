import React, { useContext } from "react";
import { ApplicantInformationContext } from "../App";
import { ApplicantPreviousAddressInfoContext } from "../App";
import { ApplicantEmploymentHistoryContext } from "../App";
import { CoAppInformationContext } from "../App";
import { CoAppPrevAddressContext } from "../App";
import { CoAppEmploymentContext } from "../App";
import EditButton from "../Components/Buttons/EditButton";
import { ButtonOne } from "../Components/Buttons/ButtonOne";
import { SignButton } from "../Components/Buttons/SignButton";
import "./ReviewStyle.css";

const ReviewPage = ({ hasCoApplicant, handleEditButton, handleFormSubmit }) => {
  const {
    applicantFirstName,
    applicantMiddleName,
    applicantLastName,
    applicantDateOfBirth,
    applicantPhoneNumber,
    applicantEmailAddress,
    applicantSsn,
    applicantStreetAddress,
    applicantCity,
    applicantState,
    applicantZipCode,
  } = useContext(ApplicantInformationContext);

  const {
    prevApplicantStreetAddress,
    prevApplicantCity,
    prevApplicantState,
    prevApplicantZipCode,
    applicantLandlordName,
    applicantLandlordNumber,
    applicantEvicted,
    applicantEvictedReason,
  } = useContext(ApplicantPreviousAddressInfoContext);

  const {
    currentEmployer,
    employmentStreetAddress,
    employmentCityName,
    employmentStateName,
    employmentZipCode,
    employmentPhone,
    grossSalary,
    jobTitle,
    dateOfEmployment,
  } = useContext(ApplicantEmploymentHistoryContext);

  const {
    coFirstName,
    coMiddleName,
    coLastName,
    coDateOfBirth,
    coEmail,
    coPhoneNumber,
    coSsn,
    coApplicantStreetAddress,
    coApplicantCity,
    coApplicantState,
    coApplicantZipCode,
  } = useContext(CoAppInformationContext);

  const {
    coPrevApplicantStreetAddress,
    coPrevApplicantCity,
    coPrevApplicantState,
    coPrevApplicantZipCode,
    coLandLordName,
    coLandlordNumber,
    coEvicted,
    coEvictedReason,
  } = useContext(CoAppPrevAddressContext);

  const {
    coCurrentEmployer,
    coEmploymentStreetAddress,
    coEmploymentCityName,
    coEmploymentStateName,
    coEmploymentZipCode,
    coEmploymentPhone,
    coGrossSalary,
    coJobTitle,
    coDateOfEmployment,
  } = useContext(CoAppEmploymentContext);

  return (
    <div className="review-container">
      <div className="review-header">
        <h2>Review Your Information</h2>
        <SignButton onClick={handleFormSubmit} label='Submit'/>
      </div>

      <div className="section">
        <h3>Applicant Information:</h3>{" "}
        <EditButton onClick={() => handleEditButton("applicantInformation")} />
        <p>
          <span>First Name:</span> {applicantFirstName}
        </p>
        <p>
          <span>Middle Name:</span> {applicantMiddleName}
        </p>
        <p>
          <span>Last Name:</span> {applicantLastName}
        </p>
        <p>
          <span>Date of Birth:</span> {applicantDateOfBirth}
        </p>
        <p>
          <span>Phone Number:</span> {applicantPhoneNumber}
        </p>
        <p>
          <span>Email Address:</span> {applicantEmailAddress}
        </p>
        <p>
          <span>SSN/ITIN:</span> {applicantSsn}
        </p>
        <p>
          <span>Street Address:</span> {applicantStreetAddress}
        </p>
        <p>
          <span>City:</span> {applicantCity}
        </p>
        <p>
          <span>State:</span> {applicantState}
        </p>
        <p>
          <span>Zip Code:</span> {applicantZipCode}
        </p>
      </div>
      <hr />

      <div className="section">
        <h3>Previous Address Information:</h3>{" "}
        <EditButton
          onClick={() => handleEditButton("applicantPreviousAddress")}
        />
        <p>
          <span>Previous Street Address:</span> {prevApplicantStreetAddress}
        </p>
        <p>
          <span>Previous City:</span> {prevApplicantCity}
        </p>
        <p>
          <span>Previous State:</span> {prevApplicantState}
        </p>
        <p>
          <span>Previous Zip Code:</span> {prevApplicantZipCode}
        </p>
        <p>
          <span>Landlord Name:</span> {applicantLandlordName}
        </p>
        <p>
          <span>Landlord Number:</span> {applicantLandlordNumber}
        </p>
        <p>
          <span>Evicted:</span> {applicantEvicted}
        </p>
        <p>
          <span>Eviction Reason:</span> {applicantEvictedReason}
        </p>
      </div>
      <hr />

      <div className="section">
        <h3>Employment History:</h3>{" "}
        <EditButton
          onClick={() => handleEditButton("applicantEmploymentHistory")}
        />
        <p>
          <span>Current Employer:</span> {currentEmployer}
        </p>
        <p>
          <span>Employment Street Address:</span> {employmentStreetAddress}
        </p>
        <p>
          <span>Employment City:</span> {employmentCityName}
        </p>
        <p>
          <span>Employment State:</span> {employmentStateName}
        </p>
        <p>
          <span>Employment Zip Code:</span> {employmentZipCode}
        </p>
        <p>
          <span>Employment Phone:</span> {employmentPhone}
        </p>
        <p>
          <span>Gross Salary:</span> {grossSalary}
        </p>
        <p>
          <span>Job Title:</span> {jobTitle}
        </p>
        <p>
          <span>Date of Employment:</span> {dateOfEmployment}
        </p>
      </div>

      {hasCoApplicant && (
        <>
          <hr />
          <div className="section">
            <h3>Co-Applicant Information:</h3>{" "}
            <EditButton onClick={() => handleEditButton("coAppInformation")} />
            <p>
              <span>First Name:</span> {coFirstName}
            </p>
            <p>
              <span>Middle Name:</span> {coMiddleName}
            </p>
            <p>
              <span>Last Name:</span> {coLastName}
            </p>
            <p>
              <span>Date of Birth:</span> {coDateOfBirth}
            </p>
            <p>
              <span>Phone Number:</span> {coPhoneNumber}
            </p>
            <p>
              <span>Email Address:</span> {coEmail}
            </p>
            <p>
              <span>SSN/ITIN:</span> {coSsn}
            </p>
            <p>
              <span>Street Address:</span> {coApplicantStreetAddress}
            </p>
            <p>
              <span>City:</span> {coApplicantCity}
            </p>
            <p>
              <span>State:</span> {coApplicantState}
            </p>
            <p>
              <span>Zip Code:</span> {coApplicantZipCode}
            </p>
          </div>
          <hr />

          <div className="section">
            <h3>Co-Applicant Previous Address Information:</h3>{" "}
            <EditButton onClick={() => handleEditButton("coAppPrevAddress")} />
            <p>
              <span>Previous Street Address:</span>{" "}
              {coPrevApplicantStreetAddress}
            </p>
            <p>
              <span>Previous City:</span> {coPrevApplicantCity}
            </p>
            <p>
              <span>Previous State:</span> {coPrevApplicantState}
            </p>
            <p>
              <span>Previous Zip Code:</span> {coPrevApplicantZipCode}
            </p>
            <p>
              <span>Landlord Name:</span> {coLandLordName}
            </p>
            <p>
              <span>Landlord Number:</span> {coLandlordNumber}
            </p>
            <p>
              <span>Evicted:</span> {coEvicted}
            </p>
            <p>
              <span>Eviction Reason:</span> {coEvictedReason}
            </p>
          </div>
          <hr />

          <div className="section">
            <h3>Co-Applicant Employment History:</h3>{" "}
            <EditButton
              onClick={() => handleEditButton("coAppEmploymentHistory")}
            />
            <p>
              <span>Current Employer:</span> {coCurrentEmployer}
            </p>
            <p>
              <span>Employment Street Address:</span>{" "}
              {coEmploymentStreetAddress}
            </p>
            <p>
              <span>Employment City:</span> {coEmploymentCityName}
            </p>
            <p>
              <span>Employment State:</span> {coEmploymentStateName}
            </p>
            <p>
              <span>Employment Zip Code:</span> {coEmploymentZipCode}
            </p>
            <p>
              <span>Employment Phone:</span> {coEmploymentPhone}
            </p>
            <p>
              <span>Gross Salary:</span> {coGrossSalary}
            </p>
            <p>
              <span>Job Title:</span> {coJobTitle}
            </p>
            <p>
              <span>Date of Employment:</span> {coDateOfEmployment}
            </p>
          </div>
        </>
      )}

      <div className="submit-button-section">
        <SignButton onClick={handleFormSubmit} label="Submit" />
      </div>
      <style jsx>{`
        .review-container {
          max-width: 600px;
          margin: 0 auto;
          padding: 20px;
          border: 1px solid #ccc;
          border-radius: 4px;
          background-color: #f9f9f9;
        }

        .section {
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }

        h2 {
          font-size: 24px;
          margin-bottom: 20px;
        }

        h3 {
          font-size: 18px;
          margin-bottom: 10px;
        }

        p {
          margin: 5px 0;
        }

        span {
          font-weight: bold;
          margin-right: 5px;
        }
      `}</style>
    </div>
  );
};

export default ReviewPage;
