// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.input-label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}

.input {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease-in-out;
}

.input:focus {
  outline: none;
  border-color: dodgerblue;
  box-shadow: 0 0 3px dodgerblue;
}

.address-input {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  margin-bottom: 16px;
}

.text-area {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease-in-out;
  resize: none;
}

@media (min-width: 600px) {
  .address-input {
    grid-template-columns: 1fr 1fr; /* Use a 2-column layout on larger screens */
    gap: 16px;
  }
}

.signature-input {
  border: 1px solid #ccc;
  font-size: 16px;
  border-top: none;
  border-left: none;
  border-right: none;
  text-align: center;
  font-family: 'Cedarville Cursive', cursive;
  transition: border-color 0.3s ease-in-out;
  font-size: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Inputs/InputStyle.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,yCAAyC;AAC3C;;AAEA;EACE,aAAa;EACb,wBAAwB;EACxB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,0BAA0B;EAC1B,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,yCAAyC;EACzC,YAAY;AACd;;AAEA;EACE;IACE,8BAA8B,EAAE,4CAA4C;IAC5E,SAAS;EACX;AACF;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,0CAA0C;EAC1C,yCAAyC;EACzC,eAAe;AACjB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap');\n\n.input-field {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 16px;\n}\n\n.input-label {\n  font-size: 14px;\n  font-weight: bold;\n  margin-bottom: 8px;\n}\n\n.input {\n  padding: 8px 12px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  font-size: 16px;\n  transition: border-color 0.3s ease-in-out;\n}\n\n.input:focus {\n  outline: none;\n  border-color: dodgerblue;\n  box-shadow: 0 0 3px dodgerblue;\n}\n\n.address-input {\n  display: grid;\n  grid-template-columns: 1fr;\n  gap: 8px;\n  margin-bottom: 16px;\n}\n\n.text-area {\n  padding: 8px 12px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  font-size: 16px;\n  transition: border-color 0.3s ease-in-out;\n  resize: none;\n}\n\n@media (min-width: 600px) {\n  .address-input {\n    grid-template-columns: 1fr 1fr; /* Use a 2-column layout on larger screens */\n    gap: 16px;\n  }\n}\n\n.signature-input {\n  border: 1px solid #ccc;\n  font-size: 16px;\n  border-top: none;\n  border-left: none;\n  border-right: none;\n  text-align: center;\n  font-family: 'Cedarville Cursive', cursive;\n  transition: border-color 0.3s ease-in-out;\n  font-size: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
