import "./InputStyle.css";

const DatePickerInput = ({ onChange, name, label, required }) => {
  return (
    <div className="input-field">
      <label className="input-label" htmlFor="birthday">
        {label}
      </label>
      <input
        required={required}
        name={name}
        onChange={onChange}
        className="input"
        type="date"
      />
    </div>
  );
};

export default DatePickerInput;
