export const applicantInformation = {
  applicantFirstName: '',
  applicantMiddleName: '',
  applicantLastName: '',
  applicantDateOfBirth: '',
  applicantPhoneNumber: '',
  applicantEmailAddress: '',
  applicantSsn: '',
  applicantStreetAddress: '',
  applicantCity: '',
  applicantState: '',
  applicantZipCode: '',
};

export const applicantPreviousAddressInformation = {
  prevApplicantStreetAddress: '',
  prevApplicantCity: '',
  prevApplicantState: '',
  prevApplicantZipCode: '',
  applicantLandlordName: '',
  applicantLandlordNumber: '',
  applicantEvicted: '',
  applicantEvictedReason: '',
};

export const applicantEmploymentHistory = {
  currentEmployer: '',
  employmentStreetAddress: '',
  employmentCityName: '',
  employmentStateName: '',
  employmentZipCode: '',
  employmentPhone: '',
  grossSalary: '',
  jobTitle: '',
  dateOfEmployment: '',
};

export const applicantSignature = {
  signature: '',
};

export const coApplicantInformation = {
  coFirstName: '',
  coMiddleName: '',
  coLastName: '',
  coDateOfBirth: '',
  coEmail: '',
  coPhoneNumber: '',
  coSsn: '',
  coApplicantStreetAddress: '',
  coApplicantCity: '',
  coApplicantState: '',
  coApplicantZipCode: '',
};

export const coApplicantPrevAddressInformation = {
  coPrevApplicantStreetAddress: '',
  coPrevApplicantCity: '',
  coPrevApplicantState: '',
  coPrevApplicantZipCode: '',
  coLandLordName: '',
  coLandlordNumber: '',
  coEvicted: '',
  coEvictedReason: '',
};

export const coApplicantEmploymentHistoryInformation = {
  coCurrentEmployer: '',
  coEmploymentStreetAddress: '',
  coEmploymentCityName: '',
  coEmploymentStateName: '',
  coEmploymentZipCode: '',
  coEmploymentPhone: '',
  coGrossSalary: '',
  coJobTitle: '',
  coDateOfEmployment: '',
};

export const coApplicantSignature = {
  coSignature: '',
};