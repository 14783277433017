import "./InputStyle.css";

export const AddressInput = ({
  streetName,
  streetValue,
  cityName,
  cityValue,
  stateName,
  stateValue,
  zipName,
  zipValue,
  label,
  onChange,
  required
}) => {
  return (
    <div>
      <div style={{ marginBottom: "8px" }}>
        <label className="input-label" htmlFor="">
          {label}
        </label>
      </div>
      <div className="address-input">
        <input
          className="input"
          type="text"
          placeholder="Street Address"
          value={streetValue}
          onChange={onChange}
          name={streetName}
          required={required}
        />
        <input
          className="input"
          type="text"
          placeholder="City"
          value={cityValue}
          onChange={onChange}
          name={cityName}
          required={required}
        />
        <input
          className="input"
          type="text"
          placeholder="State"
          value={stateValue}
          onChange={onChange}
          name={stateName}
          required={required}
        />
        <input
          className="input"
          type="text"
          placeholder="ZIP Code"
          value={zipValue}
          onChange={onChange}
          name={zipName}
          required={required}
        />
      </div>
    </div>
  );
};
