export const formatSsn = (event) => {
  const input = event.target.value;

  const formattedInput = input
    .replace(/\D/g, "")
    .replace(/(\d{0,3})(\d{0,2})(\d{0,4})/, (match, p1, p2, p3) => {
      const part1 = p1 ? `${p1}-` : "";
      const part2 = p2 ? `${p2}-` : "";
      const part3 = p3 || "";
      return `${part1}${part2}${part3}`;
    });

  return formattedInput;
};

export const formatSalary = (event) => {
  const input = event.target.value;

  const formattedInput = input
    .replace(/\D/g, "")
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

  return "$" + formattedInput;
};
