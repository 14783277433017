import "./App.css";
import { useState, createContext } from "react";

//!Validations
import {
  applicantInfoSchema,
  applicantEmploymentHistorySchema,
} from "./Validations/ApplicantInfoValidation";

//!Pages
import GreetingsPage from "./Pages/GreetingsPage";
import ApplicantInformation from "./Pages/ApplicantInformation";
import ApplicantPreviousAddressInfo from "./Pages/ApplicantPreviousAddressInfo";
import ApplicantEmploymentHistory from "./Pages/ApplicantEmploymentHistory";
import ApplicantSignature from "./Pages/ApplicantSignaure";
import AskIfCoApp from "./Pages/AskIfCoApp";
import ReviewPage from "./Pages/ReviewPage";
import CoApplicantInformation from "./Pages/CoApplicantPages/CoApplicantInformation";
import CoApplicantPreviousAddress from "./Pages/CoApplicantPages/CoApplicantPrevAddressInfo";
import CoAppEmploymentHistory from "./Pages/CoApplicantPages/CoAppEmploymentHistory";
import CoAppSignature from "./Pages/CoApplicantPages/CoApplicantSignature";
import SubmitMessage from "./Pages/SubmitMessage";

//!States
import {
  applicantInformation,
  applicantPreviousAddressInformation,
  applicantEmploymentHistory,
  applicantSignature,
  coApplicantInformation,
  coApplicantPrevAddressInformation,
  coApplicantEmploymentHistoryInformation,
  coApplicantSignature,
} from "./States";

//!Helpers
import { formatSsn, formatSalary } from "./utils/Helpers";

//!Applicant information context
export const ApplicantInformationContext = createContext();
export const ApplicantPreviousAddressInfoContext = createContext();
export const ApplicantEmploymentHistoryContext = createContext();
export const CoAppInformationContext = createContext();
export const CoAppPrevAddressContext = createContext();
export const CoAppEmploymentContext = createContext();

function App() {
  const handleEmail = () => {
    if (window.Email) {
      window.Email.send({
        SecureToken: "6538914b-1c67-421b-aa5a-6d77a7fa3dd9",
        To: "paultaylor450@gmail.com",
        From: "ptaylor@blueviewgroup.com",
        Subject: "This is the subject",
        Body: emailBody(),
      }).then((message) => console.log(message));
    }
  };

  function emailBody() {
    return (
      `<strong>FIRST NAME:</strong> ${applicantInformationData.applicantFirstName}<br><br>` +
      `<strong>MIDDLE NAME:</strong> ${applicantInformationData.applicantMiddleName}<br><br>` +
      `<strong>LAST NAME:</strong> ${applicantInformationData.applicantLastName}<br><br>` +
      `<strong>DATE OF BIRTH:</strong> ${applicantInformationData.applicantDateOfBirth}<br><br>` +
      `<strong>PHONE NUMBER:</strong> ${applicantInformationData.applicantPhoneNumber}<br><br>` +
      `<strong>EMAIL ADDRESS:</strong> ${applicantInformationData.applicantEmailAddress}<br><br>` +
      `<strong>SSN:</strong> ${applicantInformationData.applicantSsn}<br><br>` +
      `<strong>STREET ADDRESS:</strong> ${applicantInformationData.applicantStreetAddress}<br><br>` +
      `<strong>CITY:</strong> ${applicantInformationData.applicantCity}<br><br>` +
      `<strong>STATE:</strong> ${applicantInformationData.applicantState}<br><br>` +
      `<strong>ZIP CODE:</strong> ${applicantInformationData.applicantZipCode}<br><br>` +
      `<strong><h2>PREVIOUS ADDRESS</h2></strong><br>` +
      `<strong>STREET ADDRESS:</strong> ${applicantPreviousAddressData.prevApplicantStreetAddress}<br><br>` +
      `<strong>CITY:</strong> ${applicantPreviousAddressData.prevApplicantCity}<br><br>` +
      `<strong>STATE:</strong> ${applicantPreviousAddressData.prevApplicantState}<br><br>` +
      `<strong>ZIP CODE:</strong> ${applicantPreviousAddressData.prevApplicantZipCode}<br><br>` +
      `<strong>LANDLORD NAME:</strong> ${applicantPreviousAddressData.applicantLandlordName}<br><br>` +
      `<strong>LANDLORD NUMBER:</strong> ${applicantPreviousAddressData.applicantLandlordNumber}<br><br>` +
      `<strong>EVICTED:</strong> ${applicantPreviousAddressData.applicantEvicted}<br><br>` +
      `<strong>EVICTION REASON:</strong> ${applicantPreviousAddressData.applicantEvictedReason}<br><br>` +
      `<strong><h2>EMPLOYMENT HISTORY</h2></strong><br>` +
      `<strong>CURRENT EMPLOYER:</strong> ${applicantEmploymentHistoryData.currentEmployer}<br><br>` +
      `<strong>STREET ADDRESS:</strong> ${applicantEmploymentHistoryData.employmentStreetAddress}<br><br>` +
      `<strong>CITY:</strong> ${applicantEmploymentHistoryData.employmentCityName}<br><br>` +
      `<strong>STATE:</strong> ${applicantEmploymentHistoryData.employmentStateName}<br><br>` +
      `<strong>ZIP CODE:</strong> ${applicantEmploymentHistoryData.employmentZipCode}<br><br>` +
      `<strong>PHONE:</strong> ${applicantEmploymentHistoryData.employmentPhone}<br><br>` +
      `<strong>GROSS SALARY:</strong> ${applicantEmploymentHistoryData.grossSalary}<br><br>` +
      `<strong>JOB TITLE:</strong> ${applicantEmploymentHistoryData.jobTitle}<br><br>` +
      `<strong>DATE OF EMPLOYMENT:</strong> ${applicantEmploymentHistoryData.dateOfEmployment}<br><br>` +
      `<strong><h2>CO-APPLICANT INFORMATION</h2></strong><br>` +
      `<strong>FIRST NAME:</strong> ${coAppInformationData.coFirstName}<br><br>` +
      `<strong>MIDDLE NAME:</strong> ${coAppInformationData.coMiddleName}<br><br>` +
      `<strong>LAST NAME:</strong> ${coAppInformationData.coLastName}<br><br>` +
      `<strong>DATE OF BIRTH:</strong> ${coAppInformationData.coDateOfBirth}<br><br>` +
      `<strong>EMAIL:</strong> ${coAppInformationData.coEmail}<br><br>` +
      `<strong>PHONE NUMBER:</strong> ${coAppInformationData.coPhoneNumber}<br><br>` +
      `<strong>SSN:</strong> ${coAppInformationData.coSsn}<br><br>` +
      `<strong>STREET ADDRESS:</strong> ${coAppInformationData.coApplicantStreetAddress}<br><br>` +
      `<strong>CITY:</strong> ${coAppInformationData.coApplicantCity}<br><br>` +
      `<strong>STATE:</strong> ${coAppInformationData.coApplicantState}<br><br>` +
      `<strong>ZIP CODE:</strong> ${coAppInformationData.coApplicantZipCode}<br><br>` +
      `<strong><h2>PREVIOUS ADDRESS</h2></strong><br>` +
      `<strong>STREET ADDRESS:</strong> ${coAppPrevAddressData.coPrevApplicantStreetAddress}<br><br>` +
      `<strong>CITY:</strong> ${coAppPrevAddressData.coPrevApplicantCity}<br><br>` +
      `<strong>STATE:</strong> ${coAppPrevAddressData.coPrevApplicantState}<br><br>` +
      `<strong>ZIP CODE:</strong> ${coAppPrevAddressData.coPrevApplicantZipCode}<br><br>` +
      `<strong>LANDLORD NAME:</strong> ${coAppPrevAddressData.coLandLordName}<br><br>` +
      `<strong>LANDLORD NUMBER:</strong> ${coAppPrevAddressData.coLandlordNumber}<br><br>` +
      `<strong>EVICTED:</strong> ${coAppPrevAddressData.coEvicted}<br><br>` +
      `<strong>EVICTION REASON:</strong> ${coAppPrevAddressData.coEvictedReason}<br><br>` +
      `<strong><h2>EMPLOYMENT HISTORY</h2></strong><br>` +
      `<strong>CURRENT EMPLOYER:</strong> ${coAppEmploymentData.coCurrentEmployer}<br><br>` +
      `<strong>STREET ADDRESS:</strong> ${coAppEmploymentData.coEmploymentStreetAddress}<br><br>` +
      `<strong>CITY:</strong> ${coAppEmploymentData.coEmploymentCityName}<br><br>` +
      `<strong>STATE:</strong> ${coAppEmploymentData.coEmploymentStateName}<br><br>` +
      `<strong>ZIP CODE:</strong> ${coAppEmploymentData.coEmploymentZipCode}<br><br>` +
      `<strong>PHONE:</strong> ${coAppEmploymentData.coEmploymentPhone}<br><br>` +
      `<strong>GROSS SALARY:</strong> ${coAppEmploymentData.coGrossSalary}<br><br>` +
      `<strong>JOB TITLE:</strong> ${coAppEmploymentData.coJobTitle}<br><br>` +
      `<strong>DATE OF EMPLOYMENT:</strong> ${coAppEmploymentData.coDateOfEmployment}`
    );
  }

  //!Form states
  const [applicantInformationData, setApplicantInformationData] =
    useState(applicantInformation);

  const [applicantPreviousAddressData, setApplicantPreviousAddressData] =
    useState(applicantPreviousAddressInformation);

  const [applicantEmploymentHistoryData, setApplicantEmploymentHistoryData] =
    useState(applicantEmploymentHistory);

  const [applicantSignatureData, setApplicantSignatureData] =
    useState(applicantSignature);

  const [coAppInformationData, setCoAppInformationData] = useState(
    coApplicantInformation
  );

  const [coAppPrevAddressData, setCoAppPrevAddressData] = useState(
    coApplicantPrevAddressInformation
  );

  const [coAppEmploymentData, setCoAppEmploymentData] = useState(
    coApplicantEmploymentHistoryInformation
  );

  const [coAppSignatureData, setCoAppSignatureData] =
    useState(coApplicantSignature);

  const [hasCoApplicant, setHasCoApplicant] = useState(false);

  const [isEditingPage, setIsEditingPage] = useState(false);

  //!Applicant evicted handlers

  //!Error handling states
  const [applicantInfoError, setApplicantInfoError] = useState(false);

  //!Current page state
  const [currentPage, setCurrentPage] = useState("greetingsPage");

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleAskIfCoApp = (answer) => {
    if (answer === "yes") {
      setCurrentPage("coAppInformation");
      setHasCoApplicant(true);
    } else {
      setCurrentPage("reviewPage");
    }
  };

  const handleEditButton = (page) => {
    setCurrentPage(page);
    setIsEditingPage(true);
  };

  const handleSaveButton = () => {
    setCurrentPage("reviewPage");
  };

  // !On change handlers

  const handleApplicantInfoChange = (e) => {
    setApplicantInformationData((prevState) => ({
      ...prevState,
      [e.target.name]:
        e.target.name === "applicantSsn" ? formatSsn(e) : e.target.value,
    }));
  };

  const handleApplicantPreviousAddressChange = (e) => {
    setApplicantPreviousAddressData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleApplicantEmploymentHistoryChange = (e) => {
    setApplicantEmploymentHistoryData((prevState) => ({
      ...prevState,
      [e.target.name]:
        e.target.name === "grossSalary" ? formatSalary(e) : e.target.value,
    }));
  };

  const handleApplicantSignature = (e) => {
    e.preventDefault();
    setApplicantSignatureData((prevState) => ({
      ...prevState,
      signature:
        applicantInformationData.applicantFirstName +
        " " +
        applicantInformationData.applicantLastName,
    }));
  };

  const coAppInformationChange = (e) => {
    setCoAppInformationData((prevState) => ({
      ...prevState,
      [e.target.name]:
        e.target.name === "coSsn" ? formatSsn(e) : e.target.value,
    }));
  };

  const coAppPrevAddressChange = (e) => {
    setCoAppPrevAddressData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const coAppEmploymentChange = (e) => {
    setCoAppEmploymentData((prevState) => ({
      ...prevState,
      [e.target.name]:
        e.target.name === "coGrossSalary" ? formatSalary(e) : e.target.value,
    }));
  };

  const handleCoAppSignature = (e) => {
    e.preventDefault();
    setCoAppSignatureData((prevState) => ({
      ...prevState,
      coSignature:
        coAppInformationData.coFirstName +
        " " +
        coAppInformationData.coLastName,
    }));
  };

  //!Submit Handlers
  const handleApplicantInformationSubmit = async (e) => {
    e.preventDefault();
    setCurrentPage("applicantPreviousAddress");
  };

  const handleApplicantAddressInfoSubmit = (e) => {
    e.preventDefault();
    setCurrentPage("applicantEmploymentHistory");
  };

  const handleApplicantEmploymentSubmit = async (e) => {
    e.preventDefault();
    try {
      await applicantEmploymentHistorySchema.validate(
        applicantEmploymentHistoryData,
        {
          abortEarly: false,
        }
      );
      console.log("Valid");
      setApplicantInfoError(false);
      setCurrentPage("applicantSignature");
    } catch (error) {
      if (error.name === "ValidationError") {
        console.log("Validation error:", error.errors);
        setApplicantInfoError(true);
      } else {
        console.error("Unexpected error occurred:", error);
      }
    }
  };

  const handleApplicantSignatureSubmit = (e) => {
    e.preventDefault();
    setCurrentPage("askIfCoApp");
  };

  const handleCoAppInfoSubmit = (e) => {
    setCurrentPage("coAppPrevAddress");
  };

  const handleCoAppPrevAddressSubmit = (e) => {
    setCurrentPage("coAppEmploymentHistory");
  };

  const handleCoEmploymentSubmit = (e) => {
    setCurrentPage("coAppSignature");
  };

  const handleCoSignatureSubmit = (e) => {
    setCurrentPage("reviewPage");
  };

  const handleFormSubmit = () => {
    setCurrentPage("submitMessage");
    handleEmail();
  };

  return (
    <ApplicantInformationContext.Provider value={applicantInformationData}>
      <ApplicantPreviousAddressInfoContext.Provider
        value={applicantPreviousAddressData}
      >
        <ApplicantEmploymentHistoryContext.Provider
          value={applicantEmploymentHistoryData}
        >
          <CoAppInformationContext.Provider value={coAppInformationData}>
            <CoAppPrevAddressContext.Provider value={coAppPrevAddressData}>
              <CoAppEmploymentContext.Provider value={coAppEmploymentData}>
                <div className="App">
                  {currentPage === "greetingsPage" && (
                    <GreetingsPage handlePageChange={handlePageChange} />
                  )}

                  {currentPage === "applicantInformation" && (
                    <ApplicantInformation
                      handleApplicantInfoChange={handleApplicantInfoChange}
                      handleApplicantInformationSubmit={
                        handleApplicantInformationSubmit
                      }
                      applicantInfoError={applicantInfoError}
                      isEditingPage={isEditingPage}
                      handleSaveButton={handleSaveButton}
                    />
                  )}

                  {currentPage === "applicantPreviousAddress" && (
                    <ApplicantPreviousAddressInfo
                      handleApplicantPreviousAddressChange={
                        handleApplicantPreviousAddressChange
                      }
                      handleApplicantAddressInfoSubmit={
                        handleApplicantAddressInfoSubmit
                      }
                      isEditingPage={isEditingPage}
                      handleSaveButton={handleSaveButton}
                    />
                  )}

                  {currentPage === "applicantEmploymentHistory" && (
                    <ApplicantEmploymentHistory
                      handleApplicantEmploymentHistoryChange={
                        handleApplicantEmploymentHistoryChange
                      }
                      handleApplicantEmploymentSubmit={
                        handleApplicantEmploymentSubmit
                      }
                      isEditingPage={isEditingPage}
                      handleSaveButton={handleSaveButton}
                    />
                  )}

                  {currentPage === "applicantSignature" && (
                    <ApplicantSignature
                      handleApplicantSignature={handleApplicantSignature}
                      applicantSignatureData={applicantSignatureData}
                      handleApplicantSignatureSubmit={
                        handleApplicantSignatureSubmit
                      }
                    />
                  )}

                  {currentPage === "coAppInformation" && (
                    <CoApplicantInformation
                      coAppInformationChange={coAppInformationChange}
                      handleCoAppInfoSubmit={handleCoAppInfoSubmit}
                      isEditingPage={isEditingPage}
                      handleSaveButton={handleSaveButton}
                    />
                  )}

                  {currentPage === "coAppPrevAddress" && (
                    <CoApplicantPreviousAddress
                      coAppPrevAddressChange={coAppPrevAddressChange}
                      handleCoAppPrevAddressSubmit={
                        handleCoAppPrevAddressSubmit
                      }
                      isEditingPage={isEditingPage}
                      handleSaveButton={handleSaveButton}
                    />
                  )}

                  {currentPage === "coAppEmploymentHistory" && (
                    <CoAppEmploymentHistory
                      coAppEmploymentChange={coAppEmploymentChange}
                      handleCoEmploymentSubmit={handleCoEmploymentSubmit}
                      isEditingPage={isEditingPage}
                      handleSaveButton={handleSaveButton}
                    />
                  )}

                  {currentPage === "coAppSignature" && (
                    <CoAppSignature
                      handleCoAppSignature={handleCoAppSignature}
                      handleCoSignatureSubmit={handleCoSignatureSubmit}
                      coAppSignatureData={coAppSignatureData}
                    />
                  )}

                  {currentPage === "askIfCoApp" && (
                    <AskIfCoApp handleAskIfCoApp={handleAskIfCoApp} />
                  )}

                  {currentPage === "reviewPage" && (
                    <ReviewPage
                      handleFormSubmit={handleFormSubmit}
                      hasCoApplicant={hasCoApplicant}
                      handleEditButton={handleEditButton}
                    />
                  )}

                  {currentPage === "submitMessage" && <SubmitMessage />}
                </div>
              </CoAppEmploymentContext.Provider>
            </CoAppPrevAddressContext.Provider>
          </CoAppInformationContext.Provider>
        </ApplicantEmploymentHistoryContext.Provider>
      </ApplicantPreviousAddressInfoContext.Provider>
    </ApplicantInformationContext.Provider>
  );
}

export default App;
