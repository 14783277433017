import '../PageStyle.css';
import { ButtonOne } from '../../Components/Buttons/ButtonOne';
import { InputField } from '../../Components/Inputs/InputOne';
import DatePickerInput from '../../Components/Inputs/DatePicker';
import { AddressInput } from '../../Components/Inputs/AdressInput';
import { CoAppInformationContext } from '../../App';
import { useContext } from 'react';

const CoApplicantInformation = ({
  coAppInformationChange,
  applicantInfoError,
  handleCoAppInfoSubmit,
  isEditingPage,
  handleSaveButton
}) => {
  const {
    coFirstName,
    coMiddleName,
    coLastName,
    coDateOfBirth,
    coEmail,
    coPhoneNumber,
    coSsn,
    coApplicantStreetAddress,
    coApplicantCity,
    coApplicantState,
    coApplicantZipCode,
  } = useContext(CoAppInformationContext);
  return (
    <div className="content-div">
      <div className="main-container">
        <div className="content">
        <h3>Co-Applicant Information</h3>
          <form
            onSubmit={handleCoAppInfoSubmit}
            className="form-section"
            action=""
          >
            <InputField
              value={coFirstName}
              name="coFirstName"
              label="* Co-Applicant First name"
              type="text"
              onChange={coAppInformationChange}
              required="true"
            />
            <InputField
              value={coMiddleName}
              name="coMiddleName"
              label="Co-Applicant Middle name"
              type="text"
              onChange={coAppInformationChange}
              
            />
            <InputField
              value={coLastName}
              name="coLastName"
              label="* Co-Applicant Last name"
              type="text"
              onChange={coAppInformationChange}
              required="true"
            />
            <DatePickerInput
              value={coDateOfBirth}
              name="coDateOfBirth"
              label="* Co-Applicant Date of birth"
              onChange={coAppInformationChange}
              required="true"
            />
            <InputField
              value={coPhoneNumber}
              name="coPhoneNumber"
              label="* Co-Applicant Phone number"
              type="type="
              onChange={coAppInformationChange}
              required="true"
            />
            <InputField
              value={coEmail}
              name="coEmail"
              label="* Co-Applicant Email address"
              type="text"
              onChange={coAppInformationChange}
              required="true"
            />
            <InputField
              value={coSsn}
              name="coSsn"
              label="* Co-Applicant Social Security Number/ITIN"
              type="text"
              onChange={coAppInformationChange}
              max="11"
              required="true"
            />
            <AddressInput
              onChange={coAppInformationChange}
              streetValue={coApplicantStreetAddress}
              streetName="coApplicantStreetAddress"
              cityValue={coApplicantCity}
              cityName="coApplicantCity"
              stateValue={coApplicantState}
              stateName="coApplicantState"
              zipValue={coApplicantZipCode}
              zipName="coApplicantZipCode"
              type="text"
              label="* Co-Applicant Current address"
              required="true"
            />
            {applicantInfoError && (
              <p style={{ textAlign: 'center', color: 'red' }}>
                All Fields Required
              </p>
            )}
            {isEditingPage ? (
              <ButtonOne onClick={handleSaveButton} label="Save" />
            ) : (
              <ButtonOne label="Continue" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CoApplicantInformation;
