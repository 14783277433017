import '../PageStyle.css';
import { ButtonOne } from '../../Components/Buttons/ButtonOne';
import { InputField } from '../../Components/Inputs/InputOne';
import DatePickerInput from '../../Components/Inputs/DatePicker';
import { AddressInput } from '../../Components/Inputs/AdressInput';
import { CoAppEmploymentContext } from '../../App';
import { useContext } from 'react';

const CoAppEmploymentHistory = ({
  coAppEmploymentChange,
  handleCoEmploymentSubmit,
  applicantInfoError,
  isEditingPage,
  handleSaveButton
}) => {
  const {
    coCurrentEmployer,
    coEmploymentStreetAddress,
    coEmploymentCityName,
    coEmploymentStateName,
    coEmploymentZipCode,
    coEmploymentPhone,
    coGrossSalary,
    coJobTitle,
    coDateOfEmployment,
  } = useContext(CoAppEmploymentContext);
  return (
    <div className="content-div">
      <div className="main-container">
        <div className="content">
        <h3>Co-Applicant Employment History</h3>
          <form
            onSubmit={handleCoEmploymentSubmit}
            className="form-section"
            action=""
          >
            <InputField
              value={coCurrentEmployer}
              name="coCurrentEmployer"
              label="* Co-Applicant Current employer"
              type="text"
              onChange={coAppEmploymentChange}
              required="true"
            />
            <AddressInput
              onChange={coAppEmploymentChange}
              streetValue={coEmploymentStreetAddress}
              streetName="coEmploymentStreetAddress"
              cityValue={coEmploymentCityName}
              cityName="coEmploymentCityName"
              stateValue={coEmploymentStateName}
              stateName="coEmploymentStateName"
              zipValue={coEmploymentZipCode}
              zipName="coEmploymentZipCode"
              type="text"
              label="* Co-Applicant Employer address"
              required="true"
            />

            <InputField
              value={coEmploymentPhone}
              name="coEmploymentPhone"
              label="* Co-Applicant Employer phone number"
              type="text"
              onChange={coAppEmploymentChange}
              required="true"
            />

            <InputField
              value={coGrossSalary}
              name="coGrossSalary"
              label="* Co-Applicant Gross salary"
              type="text"
              onChange={coAppEmploymentChange}
              required="true"
            />

            <InputField
              value={coJobTitle}
              name="coJobTitle"
              label="* Co-Applicant Job title"
              type="text"
              onChange={coAppEmploymentChange}
            />

            <DatePickerInput
              value={coDateOfEmployment}
              name="coDateOfEmployment"
              label="* Co-Applicant Date of employment"
              onChange={coAppEmploymentChange}
              required="true"
            />
            {applicantInfoError && (
              <p style={{ textAlign: 'center', color: 'red' }}>
                All Fields Required
              </p>
            )}
            {isEditingPage ? (
              <ButtonOne onClick={handleSaveButton} label="Save" />
            ) : (
              <ButtonOne label="Continue" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CoAppEmploymentHistory;
