import "./InputStyle.css";

const TextArea = ({value, onChange, name}) => {
  return (
    <div className="input-field">
      <label className="input-label" htmlFor="textArea">
        If yes, provide the reason for eviction
      </label>
      <textarea
        className="text-area"
        name={name}
        id="textArea"
        cols="30"
        rows="10"
        onChange={onChange}
        value={value}
      ></textarea>
    </div>
  );
};

export default TextArea;
