import '../PageStyle.css'
import { ButtonOne } from '../../Components/Buttons/ButtonOne';


import { SignatureInput } from '../../Components/Inputs/SignatureInput';
import { SignButton } from '../../Components/Buttons/SignButton';

const CoAppSignature = ({
  handleApplicantSignature,
  handleApplicantEmploymentSubmit,
  applicantInfoError,
  handleCoAppSignature,
  coAppSignatureData,
  handleCoSignatureSubmit
}) => {
  return (
    <div className="content-div">
      <div className="main-container">
        <div className="content">
          <form
            onSubmit={handleApplicantSignature}
            className="form-section"
            action=""
          >
            <p style={{ textAlign: 'center' }}>
              {' '}
              I acknowledge I had an opportunity to review the company’s
              qualifying criteria, which includes the reason why my application
              may be denied, such as criminal history, credit history, current
              income, and rental history. I understand that if I do not meet the
              company’s rental qualifying selection criteria, or if I fail to
              answer any question, or if I provide false or misleading
              information, the company may reject my application.
            </p>
            <SignatureInput value={coAppSignatureData.coSignature} />
            {applicantInfoError && (
              <p style={{ textAlign: 'center', color: 'red' }}>
                All Fields Required
              </p>
            )}
            <SignButton onClick={handleCoAppSignature} label="Sign" />
            {coAppSignatureData.coSignature !== '' && (
              <ButtonOne label="Continue" onClick={handleCoSignatureSubmit}/>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CoAppSignature;